import { useEffect, useRef } from "react"
import styled from "styled-components"

import { LogBox } from "src/components/Logbox/LogBox"
import { MButton } from "src/ui/Button/MButton"

export function LogOutput({
  data,
  onClear,
  rows,
}: {
  data: string[]
  onClear?: () => void
  rows?: number
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const logElem = useRef<any>(null)
  useEffect(() => {
    if (!data.length) return
    const log = logElem?.current
    if (log) {
      log.textContent = data.join("")
      log.scrollTop = log.scrollHeight
    }
  }, [data, data.length])

  function handleClear() {
    const log = logElem?.current
    if (log) {
      log.textContent = ""
      log.scrollTop = log.scrollHeight
    }
    onClear?.()
  }

  return (
    <LogOutputBox>
      {onClear && (
        <MButton variant="minimal" onClick={handleClear}>
          Clear log
        </MButton>
      )}
      <LogBox log={data} rows={rows ?? 8} />
    </LogOutputBox>
  )
}

const LogOutputBox = styled.div`
  display: grid;
  gap: 0.5rem;
  & > button {
    display: block;
    margin-left: auto;
  }
`
